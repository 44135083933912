<template>
  <v-container class="mt-12 pt-0" style="background:#fafafa;">
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        <v-row class="pa-0 px-0">
          <v-col cols="12" class="py-0 px-0 mx-0" SPLASH>
            <v-card color="primary"  flat tile dark class="splashImage">
              <v-card-text>
                <v-row class="text-center align-center py-12 my-12">
                  <v-col cols="12" md="3">
                    
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row class="text-center align-center">
                      <v-col cols="12" md="12">
                        <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:36px;font-size:2.0rem;font-weight:bold;" > 
                          <img 
                            v-if="site && site.name && site.logo" 
                            :src="`${isLogin? site.wlogo : site.logo}`" 
                            alt="site.name" 
                            style="width:130px;cursor:pointer;"><span v-if="user && user.account && user.account.logo" class="mx-2 secondary secondary--text">.</span>
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div :class="`${arabic?'arFont':'enFont'} white--text py-3`" style="line-height:1.6;font-size:1.2rem;">
                          {{i18n.its_intro}}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          
          <v-col cols="12" LOADING v-if="loading">
            <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
          </v-col>
          <v-col cols="12" md="3" STAT v-if="done">
            <MiniStat
              :icon="'emoji_objects'"
              :title="result.one"
              :subTitle="i18n.creativity_problem"
              :color="'primary'"
            />
          </v-col>
          <v-col cols="12" md="3" STAT v-if="done">
            <MiniStat
              :icon="'warning'"
              :title="result.two"
              :subTitle="i18n.risk_taking"
              :color="'primary lighten-2'"
            />
          </v-col>
          <v-col cols="12" md="3" STAT v-if="done">
            <MiniStat
              :icon="'groups'"
              :title="result.three"
              :subTitle="i18n.com_relation"
              :color="'secondary'"
            />
          </v-col>
          <v-col cols="12" md="3" STAT v-if="done">
            <MiniStat
              :icon="'emoji_events'"
              :title="result.three"
              :subTitle="i18n.excution_managment"
              :color="'info'"
            />
          </v-col>
          
          
          <v-col cols="12" class="py-0 px-0 mx-0" FEATURE CARDS>
            <v-card color="#fff" flat tile class="py-7 px-8">
              <v-card-text class="fill-height">
                <v-row class="text-center align-center">
                  <v-col cols="12">
                    <div :class="`${arabic?'arFont':'enFont'}`" style="color:#000;line-height:36px;font-size:2.0rem;font-weight:bold;">
                      {{i18n.dashboard_title}}
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="text-center align-center">
                      <v-col cols="12" md="3" class="px-2" FEATURE v-for="(survey, s) in resource" :key="'survey-'+s">
                        <v-card 
                          class="specialSurveyCard pa-5 py-12" 
                          :style="`background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(236, 11, 67, 0.9)), url('https://source.unsplash.com/1600x400?professional,${s}');`"
                          @click="goTo(`/survey/${survey.uid}`)"
                        >
                          <v-row class="text-center align-center">
                            <v-col cols="12" md="12">
                              <v-icon size="35" color="white">check_circle_outline</v-icon>
                            </v-col>
                            <v-col cols="12" md="12">
                              <h3 :class="`${arabic?'arFont':'enFont'} py-3`" style="color:#fff;">
                                {{survey.name}}
                              </h3>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Notifications from '@/components/Notifications'
import MiniStat from '@/components/MiniStat'
import axios from 'axios';
const headers = {'Accept': 'application/json'};

export default {
  components: {Notifications, MiniStat},
  data() {
    return {
      loading:false,
      dialog:false,
      open:[],
      result:null,
      done:false,
      resource:[],
      
    }
  },
  methods: {
    
    filterObject(item, queryText, itemText) {
      let v = Object.values(item).filter(x=>typeof x == 'string')
      let a = false
      v.forEach(x=>{
        if(x.toLowerCase().includes(queryText.toLowerCase())) a = true
      })
      if(itemText && typeof itemText == 'string' && itemText.toLowerCase().includes(queryText.toLowerCase())) a = true
      return a
    },
    
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },

    
    async loadResource() {
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.resource = await this.$store.dispatch('getResource', {resource:'/all-surveys'})
        this.loading = false
        res()
      })
    },
    async loadHome(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        await this.loadResource()
        
        try {
          gtag('event', `dashboard_visit`);
          gtag('event', `dashboard_visit_${this.user.account.name}`);
          gtag('event', `dashboard_visit_done_${this.done}`);
        } catch (error) {
          // console.log(error)
        }
        this.loading = false
        res(true)
      })
    }
    
  },
  computed:{
    cc(){
      return this.$store.state.cc
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      console.log(role)
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
    // countries(){
    //   return this.$store.state.countries
    // },
    country(){
      return this.$store.state.country
    },
  },
  watch:{
    
  },
  async created(){
    await this.loadHome()
  },
}
</script>

<style>
.flagBtn {
  height: 80px !important;
}

.specialSurveyCard {
  
  background-position: center center;
  background-size: cover;
}
</style>