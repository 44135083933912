<template>
  <v-card outlined>
    <v-card-text class="pa-0">
      <v-row class="ma-0 align-center text-center">
        <v-col cols="6" :class="`${color} py-5 px-0 mx-0 white--text`" SPLASH>
          <v-icon size="20px" color="white">{{icon}}</v-icon>
          <div :class="`${arabic?'arFont':'enFont'}`">{{ subTitle }}</div>
        </v-col>
        <v-col cols="6" :class="`${arabic?'arFont':'enFont'}`" SPLASH>
          <v-progress-circular size="50" width="12" :value="parseFloat(title)" :color="color" background="primary">
            <span style="font-size:11px;">{{parseFloat(title)}}</span>
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>    
</template>

<script>
export default {
  props: [
    "icon",
    "title",
    "subTitle",
    "color",
  ],
  methods:{
    goTo(path) {
      this.$router.push(path)
    },
  },
  computed:{
    cc(){
      return this.$store.state.cc
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      const items = [...this.$store.state.modelMenu]
      let user = {...this.$store.state.user}
      let role = user.role
      this.$store.menu = items.map(x=>{
        if(x.subs) {
          return {
            ...x, 
            subs:x.subs.filter(x=>x&&x.roles.includes(role))
          }
        } else if(x&&x.roles.includes(role)) {
          return {
            ...x
          }
        }
      }).filter(x=>x&&x.roles.includes(role))
      return this.$store.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications
    },
    // countries(){
    //   return this.$store.state.countries
    // },
    country(){
      return this.$store.state.country
    },
  },
};
</script>